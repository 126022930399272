import React from 'react';

export default function AnalyticsComingSoon() {
  const styles = {
    container: {
      height: '100vh', // Full viewport height
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'transparent', // Ensure no background color
    },
    text: {
      fontSize: '48px', // Big font size
      fontWeight: 'bold',
      background: 'linear-gradient(126deg, #8a51ff -9.26%, #39b2ff 79.86%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      backgroundClip: 'text',
      textFillColor: 'transparent',
      textAlign: 'center',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.text}>
        Analytics Coming Soon
      </div>
    </div>
  );
}
